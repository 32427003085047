import htmlDocx from 'html-docx-js/dist/html-docx';

const CreateDocument = (html: string, filename: string) => {
    const blob = htmlDocx.asBlob(html);

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.style.display = "none";
    a.click();
    a.remove();
    setTimeout(function () {
        window.URL.revokeObjectURL(url);
    }, 10000)
}

export default {
    CreateDocument
}